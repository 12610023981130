import { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import useStateWithLocalStorage from '../helpers/useStateWithLocalStorage';
import { parseJwt } from '../helpers/auth';

export default function useWidget() {
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const [isUploadFront, setIsUploadFront] = useState(false);
  const [isUploadBack, setIsUploadBack] = useState(false);
  const [documentId, setDocumentId] = useStateWithLocalStorage('documentId');
  const [product, setProduct] = useStateWithLocalStorage('product');
  const [token, setToken] = useStateWithLocalStorage('token');

  async function updateDocumentImage(imageAttribute, imageUrl) {
    const updateFields = {};
    updateFields[imageAttribute] = imageUrl;
    const settings = {
      method: 'POST',
      credentials: 'include',
      headers: {
          Accept: 'application/json',
          // 'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({_id: documentId, ...updateFields})
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/documents`,
      settings,
    );
  }

  useEffect(() => {
    async function createEmptyDocument() {
      const settings = {
        credentials: 'include',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({"product" : JSON.parse(product)}),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/documents`,
        settings,
      );
      const result = await response.json();
      if (result && result.error) {
        console.log('error during doc update: ' + result.message);
      } else {
        setDocumentId(result);
        console.log(`setting document: ${result}`)
      }
    }

    if (!documentId) {
      console.log('asking for new doc...')
      createEmptyDocument();
    }

  }, []);

  let widget1 = window.cloudinary.createUploadWidget(
    {
      cloudName: 'tidp',
      uploadPreset: 'fz7jch0p',
      sources: ['local', 'camera', 'dropbox', 'google_drive'],
      clientAllowedFormats: ["png", "jpg", "jpeg"],
      cropping: true,
      croppingAspectRatio: 1.5,
      showSkipCropButton: false,
      folder: parseJwt(token).userId,
      resourceType: 'image',
      minImageWidth: '700',
      maxImageWidth: '2000',
      minImageHeight: '500',
      maxImageHeight: '1500',
      language: currentLanguageCode,
      text: {
        he: {
          or: 'אוֹ',
          menu: {
            files: 'תמונה',
          },
          queue: {
            upload_more: 'העלה עוד',
            done: 'בוצע',
          },
          camera: {
            capture: 'Capture',
            cancel: 'Cancel',
            take_pic: 'Take a picture and upload it',
            explanation:
              'Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.',
            camera_error: 'Failed to access camera',
            retry: 'Retry Camera',
            file_name: 'Camera_{{time}}',
          },
          dropbox: {
            no_auth_title: 'Upload files from your Dropbox account.',
            no_auth_action: 'Connect to Dropbox',
            no_photos: 'No Photos',
            no_files: 'No Files',
            root_crumb: 'Root',
            list_headers: {
              select: 'Select',
              name: 'Name',
              modified: 'Modified',
            },
            menu: {
              browse: 'Browse',
              recent: 'Recent',
            },
            authenticating: 'Authenticating...',
          },
          google_drive: {
            no_auth_title: 'Upload files from your Google Drive.',
            no_auth_action: 'Connect to Google Drive',
            search: {
              placeholder: 'Search...',
              reset: 'Reset search',
            },
            grid: {
              folders: 'Folders',
              files: 'Image',
              empty_folder: 'This folder is empty.',
            },
            recent: 'Recent',
            starred: 'Starred',
            my_drive: 'My Drive',
            shared_drive: 'Shared drives',
            search_results: 'Search results',
            shared_with_me: 'Shared with me',
            name: 'Name',
            modifiedTime: 'Last modified',
            modifiedByMeTime: 'Last modified by me',
            viewedByMeTime: 'Last opened by me',
            notifications: {
              retrieve_failed:
                'Failed to retrieve upload data from Google Drive.',
            },
          },
          local: {
            browse: 'לְדַפדֵף',
            dd_title_single: 'Drag and Drop an asset here',
            dd_title_multi: 'גרור ושחרר נכסים כאן',
            drop_title_single: 'Drop a file to upload',
            drop_title_multiple: 'Drop files to upload',
          },
        },
        ar: {
          or: 'أو',
          menu: {
            files: 'ملفاتي',
          },
          queue: {
            upload_more: 'تحميل المزيد',
            done: 'فعله',
          },
          camera: {
            capture: 'Capture',
            cancel: 'Cancel',
            take_pic: 'Take a picture and upload it',
            explanation:
              'Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.',
            camera_error: 'Failed to access camera',
            retry: 'Retry Camera',
            file_name: 'Camera_{{time}}',
          },
          dropbox: {
            no_auth_title: 'Upload files from your Dropbox account.',
            no_auth_action: 'Connect to Dropbox',
            no_photos: 'No Photos',
            no_files: 'No Files',
            root_crumb: 'Root',
            list_headers: {
              select: 'Select',
              name: 'Name',
              modified: 'Modified',
            },
            menu: {
              browse: 'Browse',
              recent: 'Recent',
            },
            authenticating: 'Authenticating...',
          },
          google_drive: {
            no_auth_title: 'Upload files from your Google Drive.',
            no_auth_action: 'Connect to Google Drive',
            search: {
              placeholder: 'Search...',
              reset: 'Reset search',
            },
            grid: {
              folders: 'Folders',
              files: 'Files',
              empty_folder: 'This folder is empty.',
            },
            recent: 'Recent',
            starred: 'Starred',
            my_drive: 'My Drive',
            shared_drive: 'Shared drives',
            search_results: 'Search results',
            shared_with_me: 'Shared with me',
            name: 'Name',
            modifiedTime: 'Last modified',
            modifiedByMeTime: 'Last modified by me',
            viewedByMeTime: 'Last opened by me',
            notifications: {
              retrieve_failed:
                'Failed to retrieve upload data from Google Drive.',
            },
          },
          local: {
            browse: 'تصفح',
            dd_title_single: 'Drag and Drop an asset here',
            dd_title_multi: 'اسحب الأصول وأفلتها هنا',
            drop_title_single: 'Drop a file to upload',
            drop_title_multiple: 'Drop files to upload',
          },
        },
      },
    },
    async (error, result) => {
      if (!error && result && result.event === 'success') {
        console.log(result.info);
        setIsUploadFront(true);
        document
          .getElementById('imagefront')
          .setAttribute('src', result.info.secure_url);
        await updateDocumentImage('nationalLicenseAttributes.frontImageUrl', result.info.secure_url);
      }
    }
  );

  let widget2 = window.cloudinary.createUploadWidget(
    {
      cloudName: 'tidp',
      uploadPreset: 'fz7jch0p',
      sources: ['local', 'camera', 'dropbox', 'google_drive'],
      clientAllowedFormats: ["png", "jpg", "jpeg"],
      cropping: true,
      croppingAspectRatio: 1.5,
      showSkipCropButton: false,
      folder: parseJwt(token).userId,
      resourceType: 'image',
      minImageWidth: '700',
      maxImageWidth: '2000',
      minImageHeight: '500',
      maxImageHeight: '1500',
      language: currentLanguageCode,
      text: {
        he: {
          or: 'אוֹ',
          menu: {
            files: 'תמונה',
          },
          queue: {
            upload_more: 'העלה עוד',
            done: 'בוצע',
          },
          camera: {
            capture: 'Capture',
            cancel: 'Cancel',
            take_pic: 'Take a picture and upload it',
            explanation:
              'Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.',
            camera_error: 'Failed to access camera',
            retry: 'Retry Camera',
            file_name: 'Camera_{{time}}',
          },
          dropbox: {
            no_auth_title: 'Upload files from your Dropbox account.',
            no_auth_action: 'Connect to Dropbox',
            no_photos: 'No Photos',
            no_files: 'No Files',
            root_crumb: 'Root',
            list_headers: {
              select: 'Select',
              name: 'Name',
              modified: 'Modified',
            },
            menu: {
              browse: 'Browse',
              recent: 'Recent',
            },
            authenticating: 'Authenticating...',
          },
          google_drive: {
            no_auth_title: 'Upload files from your Google Drive.',
            no_auth_action: 'Connect to Google Drive',
            search: {
              placeholder: 'Search...',
              reset: 'Reset search',
            },
            grid: {
              folders: 'Folders',
              files: 'Files',
              empty_folder: 'This folder is empty.',
            },
            recent: 'Recent',
            starred: 'Starred',
            my_drive: 'My Drive',
            shared_drive: 'Shared drives',
            search_results: 'Search results',
            shared_with_me: 'Shared with me',
            name: 'Name',
            modifiedTime: 'Last modified',
            modifiedByMeTime: 'Last modified by me',
            viewedByMeTime: 'Last opened by me',
            notifications: {
              retrieve_failed:
                'Failed to retrieve upload data from Google Drive.',
            },
          },
          local: {
            browse: 'לְדַפדֵף',
            dd_title_single: 'Drag and Drop an asset here',
            dd_title_multi: 'גרור ושחרר נכסים כאן',
            drop_title_single: 'Drop a file to upload',
            drop_title_multiple: 'Drop files to upload',
          },
        },
        ar: {
          or: 'أو',
          menu: {
            files: 'ملفاتي',
          },
          queue: {
            upload_more: 'تحميل المزيد',
            done: 'فعله',
          },
          camera: {
            capture: 'Capture',
            cancel: 'Cancel',
            take_pic: 'Take a picture and upload it',
            explanation:
              'Make sure that your camera is connected and that your browser allows camera capture. When ready, click Capture.',
            camera_error: 'Failed to access camera',
            retry: 'Retry Camera',
            file_name: 'Camera_{{time}}',
          },
          dropbox: {
            no_auth_title: 'Upload files from your Dropbox account.',
            no_auth_action: 'Connect to Dropbox',
            no_photos: 'No Photos',
            no_files: 'No Files',
            root_crumb: 'Root',
            list_headers: {
              select: 'Select',
              name: 'Name',
              modified: 'Modified',
            },
            menu: {
              browse: 'Browse',
              recent: 'Recent',
            },
            authenticating: 'Authenticating...',
          },
          google_drive: {
            no_auth_title: 'Upload files from your Google Drive.',
            no_auth_action: 'Connect to Google Drive',
            search: {
              placeholder: 'Search...',
              reset: 'Reset search',
            },
            grid: {
              folders: 'Folders',
              files: 'Files',
              empty_folder: 'This folder is empty.',
            },
            recent: 'Recent',
            starred: 'Starred',
            my_drive: 'My Drive',
            shared_drive: 'Shared drives',
            search_results: 'Search results',
            shared_with_me: 'Shared with me',
            name: 'Name',
            modifiedTime: 'Last modified',
            modifiedByMeTime: 'Last modified by me',
            viewedByMeTime: 'Last opened by me',
            notifications: {
              retrieve_failed:
                'Failed to retrieve upload data from Google Drive.',
            },
          },
          local: {
            browse: 'تصفح',
            dd_title_single: 'Drag and Drop an asset here',
            dd_title_multi: 'اسحب الأصول وأفلتها هنا',
            drop_title_single: 'Drop a file to upload',
            drop_title_multiple: 'Drop files to upload',
          },
        },
      },
    },
    async (error, result) => {
      if (!error && result && result.event === 'success') {
        console.log(result.info);
        setIsUploadBack(true);
        document
          .getElementById('imageback')
          .setAttribute('src', result.info.secure_url);
          await updateDocumentImage('nationalLicenseAttributes.backImageUrl', result.info.secure_url);
        }
    }
  );

  return {
    isUploadFront,
    setIsUploadFront,
    isUploadBack,
    setIsUploadBack,
    widget1,
    widget2,
  };
}
