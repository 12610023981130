import { Card, ListGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { useSelector } from 'react-redux';
// import useStateWithLocalStorage from "../../../helpers/useStateWithLocalStorage";

import "./style.css";

export default function ProductCard(props) {
  const { enabled, name, price, delivery, time, features, icon, onClick, active } =
    props;
  const { t } = useTranslation();
  // const user = useSelector((state) => state.user.user);
  // const [token, setToken] = useStateWithLocalStorage('token');

  return (
    <>
      {/* <Card
        className="products-container"
        style={{ width: "50rem", height: "37rem" }}
      >
        <Card.Header className="card-header h2 py-4">{name}</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item
              variant="secondary"
              style={{ fontSize: 50, color: "orange" }}
            >
              {price}$
            </ListGroup.Item>
            <ListGroup.Item>{icon}</ListGroup.Item>
            <ListGroup.Item>
              {t("readyIn")} {time}
            </ListGroup.Item>
            <ListGroup.Item style={{ fontSize: 22, color: "orange" }}>
              {features}
            </ListGroup.Item>
            <ListGroup.Item className="mb-5">
              {t("validOneYear")}
            </ListGroup.Item>
            <ListGroup.Item variant="secondary">
              <Button
                variant="outline-info"
                disabled={!enabled}
                className="px-5 border-2 btn-blue"
                size="lg"
                onClick={onClick}
              >
                <strong>
                  {enabled ? t("buy_button") : t("not_available")}
                </strong>
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card> */}
      {/* <div class="col-lg-4 d-inline-block p_col"> */}
      <div class={active ? "active product_grid mb-4" : "product_grid mb-4"}>
          <div class="prod_icon text-center">
            {icon}
          </div>
          <div class="prod_tit_price">
            <div class="d-flex">
            <div class="product_title d-inline-block">{t("digital_license")}<br/>{name}</div>
              <span class="prod_price d-inline-block">${price}</span>
            </div>
          </div>
          <div class="prod_det">
            <div class="small_desc">{t("validOneYear")}</div>
            <div class="small_desc">{features}</div>
            <div class="small_desc">
              {t("readyIn")} {time}
            </div>
          </div>
          <div class="prod_btn text-center">
            <Button
              variant="outline-info"
              disabled={!enabled}
              className="px-5 border-2"
              size="lg"
              onClick={onClick}
            >
              <strong>{enabled ? t("buy_button") : t("not_available")}</strong>
            </Button>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
