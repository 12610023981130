import { useTranslation } from "react-i18next";

export default function Footer({showLegal}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-light d-flex justify-content-center align-items-center py-3 mt-auto">
          <ul className="list-inline small ">
            <li key="year">
              {t("footer")} {new Date().getFullYear()} &copy;
            </li>
            <li key="cookiePolicy" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/GDPR Cookie Policy.pdf">
                GDPR Cookie Policy
              </a>
            </li>
            <li key="privacyPolicy" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/GDPR Privacy Policy.pdf">
                GDPR Privacy Policy
              </a>
            </li>
            <li key="guarantees" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/Guarantees.pdf">Guarantees</a>
            </li>
            <li key="disclaimer" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/Legal Disclaimer.pdf">
                Legal Disclaimer
              </a>
            </li>
            <li key="privacy" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/Privacy and Security.pdf">
                Privacy and Security
              </a>
            </li>
            <li key="shippingPolicy" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/Shipping Policy.pdf">Shipping Policy</a>
            </li>
            <li key="terms" className={!showLegal ? 'd-none' : ''}>
              <a href="assets/documents/Terms and Conditions.pdf">
                Terms and Conditions
              </a>
            </li>
          </ul>
      </div>
    </>
  );
}
