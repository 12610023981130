import { Table, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";

import Header from "../../components/marginals/Header";
import Footer from "../../components/marginals/Footer";
import "./style.css";
import useStateWithLocalStorage from "../../helpers/useStateWithLocalStorage";
import { Icon } from "@iconify/react";

export default function UserAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [token, setToken] = useStateWithLocalStorage("token");
  const [documentId, setDocumentId] = useStateWithLocalStorage("documentId");
  const [documents, setDocuments] = useState([]);
  const { decodedToken, isExpired } = useJwt(token);
  const isAdmin = decodedToken && decodedToken.isAdmin;

  const productIcons = {
    regular_idp: "simple-icons:johndeere",
    express_idp: "fluent:access-time-24-regular",
    emergency_idp: "bi:lightning",
  };

  useEffect(() => {
    setDocumentId("");
    async function fetchData() {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/documents`,
        {
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      console.log(result);
      setDocuments(result);
    }
    fetchData();
  }, []);

  useEffect(() => {
    document.title = t("account")
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex flex-column align-items-center py-2 px-5">
        <h2>{t("account")}</h2>
      </div>
      <div className="d-flex flex-column align-items-center py-2 px-5">
        <h3 className="mb-5">{t("doc_title")}</h3>
        {documents && documents.length ? (
          <Table
            striped
            bordered
            hover
            size="sm"
            className={isAdmin ? "fs-6" : ""}
          >
            <thead>
              <tr key="header">
                <th>#</th>
                <th>{t("type")}</th>
                <th>{t("status_header")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((item) => {
                var id = item._id.toString(),
                  ctr = 0;
                var timestamp = parseInt(id.slice(ctr, (ctr += 8)), 16);
                var machineID = parseInt(id.slice(ctr, (ctr += 6)), 16);
                var processID = parseInt(id.slice(ctr, (ctr += 4)), 16);
                var counter = parseInt(id.slice(ctr, (ctr += 6)), 16);

                return [
                  <tr key={id}>
                    <td>{isAdmin ? item.userMail : counter}</td>
                    <td>
                      <Icon
                        icon={productIcons[item.productName]}
                        width="2em"
                        height="2em"
                      />{" "}
                    </td>
                    <td> {t(item.status)}</td>
                    <td>
                      <DropdownButton id="actions" title="">
                        <Dropdown.Item
                          href={`${process.env.REACT_APP_API_SERVER_URL}/api/documents/pdf/${id}`}
                          disabled={item.status !== "ready"}
                        >
                          {t("view")}
                        </Dropdown.Item>
                        {isAdmin
                          ? [
                              <Dropdown.Item target="_blank" href={`document/${id}`}>
                                {t("edit")}
                              </Dropdown.Item>,
                            ]
                          : ""}
                      </DropdownButton>
                    </td>
                  </tr>,
                ];
              })}
            </tbody>
          </Table>
        ) : (
          t("no_docs")
        )}

        <Button
          variant="info"
          className="btn-white mt-5"
          size="lg"
          onClick={() => navigate("/products")}
        >
          {t("newdoc_button")}
        </Button>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
}
