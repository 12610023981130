import React, { useEffect } from "react"
import LogRocket from "../LogRocket"
import useScript from "../useScript"
import { useLocation } from "react-router-dom"

const TechFooter = ({ enableLogRocket = false }) => {
  const location = useLocation()

  // const rtl = dir === 'rtl';
  useScript("https://www.googletagmanager.com/gtag/js?id=G-6SLSP4G8N4")

  useEffect(() => {
    if (
      window.location.hostname !== "localhost" &&
      window.location.search.indexOf("ignore=true") === -1
    ) {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("js", new Date())
      gtag("config", "G-6SLSP4G8N4", {
        cookie_flags: "max-age=7200;secure",
      })
      gtag("get", "G-6SLSP4G8N4", "client_id", (client_id) => {
        window.cid = client_id
      })

      // hack to allow gtag usage everywhere
      window.gtag = gtag
    } else {
      console.log("ignore analytics");
    }
  }, [])

  useEffect(() => {
    if (window.gtag) {
      console.log("track a pageview " + location.pathname + location.search);
      window.gtag("config", "G-6SLSP4G8N4", {
        page_path: location.pathname + location.search,
      })
    }
  }, [location.pathname])

  return (
    <div className="footer-tech">
      {/* <!-- Facebook Pixel Code --> */}
      {/* <img alt="fb tracking pixel" height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=1265622610167231&ev=PageView&noscript=1" /> */}
      {enableLogRocket === true ? (
        <LogRocket creds={process.env.REACT_APP_LOGROCKET_KEY} />
      ) : (
        ""
      )}
    </div>
  )
}

export default TechFooter
