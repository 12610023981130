import { Container, Row, Col } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
// import { detectProduct } from '../../../features/documentSlice';
import useStateWithLocalStorage from '../../../helpers/useStateWithLocalStorage';

import ProductCard from './ProductCard';

export default function Products(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const dispatch = useDispatch();
  const [product, setProduct] = useStateWithLocalStorage('product');
  console.log(product);

  useEffect(() => {
    setProduct("");
    async function fetchData() {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/products`
      );
      const result = await response.json();
      setData(result);
    }
    fetchData();
  }, []);

  useEffect(() => {
    // do not navigate away if we still didn't loaded any data(products)
    // because we might have product in the localStorage during initial render, before
    // we reset empty product in the onload effect above
    if (data.length > 0 && product !== "") {
      console.log('product was set, navigating...');
      navigate('/license');
    }
  }, [product])

  return (
    <div id='products'>
      <h1 className='mb-5 mt-4'>
        {t('select_a')} <span style={{ color: 'orange' }}>{t('product')}</span>{' '}
      </h1>

      <Container className='d-flex justify-content-center mb-5'>
        <Row xs={1} md={2} className='g-5'>
          {data.map((document) => (
            document.enabled ?
              (
                <Col
                  key={document._id}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  className='d-flex justify-content-center'
                >
                  <ProductCard
                    enabled={document.enabled}
                    name={t(document.name)}
                    price={document.price}
                    time={t(document.deliveryTime)}
                    features={t(document.features, { joinArrays: ', ' })}
                    icon={<Icon icon={document.iconClass} width='80' height='80' />}
                    onClick={() => {
                      setProduct(JSON.stringify(document));
                    }}
                  />
                </Col>
              ) : ""
          ))}
        </Row>
      </Container>
    </div>
  );
}
