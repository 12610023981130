import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./style.css";
import "./responsive.css";

export default function CommonContent() {
  const { t } = useTranslation();

  return (
    <>
      <div className="banner_section">
        <div className="image">
          <div className="bg_full"></div>
          <div className="banner_content">
            <div className="ban_cont">
              <div className="container">
                <div className="banner_title">
                  {t('main_title_line1')}
                  <span>{t('main_title_line2')}</span>
                </div>
                <div className="desc text-white">
                {t('main_text_line1')}
                  <br />
                  {t('main_text_line2')}
                </div>
                <a href="#products" className="btn">
                {t('start_button')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="driving_abroad_section">
        <div className="container">
          <div className="section_title text-center">
          {t('recommended_text')}
          </div>
          <div className="driving_abroad_block">
            <div className="img_grids">
              <div className="row">
                <div className="col-lg-4 d-flex justify-content-center position-relative _col">
                  <div className="img_grid">
                    <div className="gr_img">
                      <img
                        className="_img"
                        src="assets/images/da_img_1.png"
                        alt="Passport"
                      />
                      <div className="_mobile small_desc text-center">
                      {t('passport')}
                      </div>
                    </div>
                  </div>
                  <div className="check_img">
                    <img
                      className="_img"
                      src="assets/images/check.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-center position-relative align-items-center _col">
                  <div className="img_grid _2">
                    <div className="gr_img">
                      <img
                        className="_img"
                        src="assets/images/da_img_2.png"
                        alt="driver's license"
                      />
                      <div className="_mobile small_desc text-center">
                      {t('driverLicenseLine1')}<br/>{t('driverLicenseLine2')}
                      </div>
                    </div>
                  </div>
                  <div className="check_img">
                    <img
                      className="_img"
                      src="assets/images/check.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-center position-relative _col">
                  <div className="img_grid">
                    <div className="gr_img">
                      <img
                        className="_img"
                        src="assets/images/da_img_3.png"
                        alt="International Driving Permit"
                      />
                      <div className="_mobile small_desc text-center">
                      {t('idp')}
                      </div>
                    </div>
                  </div>
                  <div className="check_img">
                    <img
                      className="_img"
                      src="assets/images/check.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="img_tit">
              <div className="row">
                <div className="col-lg-4 d-flex justify-content-center">
                  <div className="small_desc text-center">{t('passport')}</div>
                </div>
                <div className="col-lg-4 d-flex justify-content-center">
                  <div className="small_desc text-center">
                  {t('driverLicenseLine1')}<br/>{t('driverLicenseLine2')}
                  </div>
                </div>
                <div className="col-lg-4 d-flex justify-content-center">
                  <div className="small_desc text-center">
                  {t('idp')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
