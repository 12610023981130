// import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isValid } from './auth';
import useStateWithLocalStorage from './useStateWithLocalStorage';

export default function ProtectedRoutes() {
  const [token, setToken] = useStateWithLocalStorage('token');

  // // listen to token becomes empty
  // useEffect(() => {
  //   if (!isValid(token)) {
  //     console.log('token has expired, need to relogin to continue...')
  //     setToken("")
  //   }
  // }, [token]);
  
  return ( isValid(token) ? <Outlet /> : <Navigate to='/login' />)
}