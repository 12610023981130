import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/marginals/Header';
import Footer from '../../components/marginals/Footer';
import './style.css';
import useStateWithLocalStorage from '../../helpers/useStateWithLocalStorage';

export default function Document() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [token, setToken] = useStateWithLocalStorage('token');
  const [smsStatus, setSmsStatus] = useState("send");
  const [userStatus, setUserStatus] = useState("save");
  const [docStatus, setDocStatus] = useState("save");
  const [message, setMessage] = useState("Please reply with new images of the front and the back of your driving license.");
  const [document, setDocument] = useState(null);
  const [documentEdit, setDocumentEdit] = useState("");
  const [user, setUser] = useState(null);
  const [userEdit, setUserEdit] = useState("");
  let { docId } = useParams();
  let profileImage = document && document.nationalLicenseAttributes && document.nationalLicenseAttributes.frontImageUrl ? document.nationalLicenseAttributes.frontImageUrl.replace("upload", "upload/w_2.0/w_300,h_350,c_thumb,g_face,e_sharpen,e_improve") : ""
  if (profileImage && document?.nationalLicenseAttributes?.frontImageRotationDegree != 0) {
    profileImage = profileImage.replace("w_300,h_350",`a_${document.nationalLicenseAttributes.frontImageRotationDegree},w_300,h_350`)
  }

  useEffect(() => {
    setDocumentEdit(JSON.stringify(document, undefined, 2))
  }, [document])

  useEffect(() => {
    setUserEdit(JSON.stringify(user, undefined, 2))
  }, [user])

  useEffect(() => {
    async function fetchData() {
      const docResponse = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/documents/${docId}`,
        {
          credentials: 'include',
        }
      );
      const docResult = await docResponse.json();
      console.log(docResult)
      setDocument(docResult);

      const userResponse = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/user/${docResult.userMail}`,
        {
          credentials: 'include',
        }
      );
      const userResult = await userResponse.json();
      console.log(userResult)
      setUser(userResult);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const updateUser = async () => {
      const settings = {
        credentials: 'include',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: userEdit,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/user/`,
          settings
        );
  
        console.log(response)
        if (response.status === 200) {
          setUserStatus('saved')
          setUser(JSON.parse(userEdit))
        } else {
          setUserStatus('failed')
        }  
      } catch (e) {
        console.log(e)
        setUserStatus('failed')
      }
    }

    if (userStatus === "saving") {
      //send the sms and set status to 'sent' or 'failed'
      updateUser();
    }
  }, [userStatus]);


  useEffect(() => {
    const updateDocument = async () => {
      const settings = {
        credentials: 'include',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: documentEdit,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/documents/`,
          settings
        );
  
        console.log(response)
        if (response.status === 200) {
          setDocStatus('saved')
          setDocument(JSON.parse(documentEdit))
        } else {
          setDocStatus('failed')
        }  
      } catch (e) {
        console.log(e)
        setDocStatus('failed')
      }
    }

    if (docStatus === "saving") {
      //send the sms and set status to 'sent' or 'failed'
      updateDocument();
    }
  }, [docStatus]);

  useEffect(() => {
    const sendSms = async () => {
      const settings = {
        credentials: 'include',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: user.email,
          message: message,
        }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/user/message`,
          settings
        );
  
        console.log(response)
        if (response.status === 200) {
          setSmsStatus('sent')
        } else {
          setSmsStatus('failed')
        }  
      } catch (e) {
        console.log(e)
        setSmsStatus('failed')
      }
    }

    if (smsStatus === "sending") {
      //send the sms and set status to 'sent' or 'failed'
      sendSms();
    }
  }, [smsStatus]);

  return (
    <div className='d-flex flex-column min-vh-100'>
      <Header />
      <div className='d-flex justify-content-end mx-5'>
        <h2>{t('account')}</h2>
      </div>
      <div className='d-flex flex-column align-items-center py-2'>
        <h3 className='mb-5'>{t('doc_title')}</h3>
        <h3>frontImageUrl</h3>
        <img alt="frontImage" width="100%" src={document && document.nationalLicenseAttributes ? document.nationalLicenseAttributes.frontImageUrl : ""} />
        <h3>backImageUrl</h3>
        <img alt="backImage" width="100%" src={document && document.nationalLicenseAttributes ? document.nationalLicenseAttributes.backImageUrl : ""} />
        <h3>profile</h3>
        <img alt="profileImage" src={profileImage} />
        <h3>document</h3>
        <a href={`${process.env.REACT_APP_API_SERVER_URL}/api/documents/pdf/${document ? document._id : ""}`}><Button variant='info' className='btn-white' size='lg'>{t('preview')}</Button></a>
        <textarea id="document" value={documentEdit} onChange={(e) => setDocumentEdit(e.target.value)}></textarea>
        <Button variant='info' className='btn-white' size='lg' onClick={(e) => {
          setDocStatus("saving");
        }}>{t(docStatus)}</Button>
        <h3>user</h3>
        <textarea id="user" value={userEdit} onChange={(e) => setUserEdit(e.target.value)}></textarea>
        <Button variant='info' className='btn-white' size='lg' onClick={(e) => {
          setUserStatus("saving");
        }}>{t(userStatus)}</Button>
        <h3>send sms</h3>
        <textarea id="sms" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        <Button variant='info' className='btn-white' size='lg' onClick={(e) => {
          setSmsStatus("sending");
        }}>{t(smsStatus)}</Button>
      </div>
      <Footer className='mt-auto' />
    </div>
  );
}
