import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';

import Language from "../../Language";
// import { userIsUnauthenticated } from '../../../features/userSlice';
import useStateWithLocalStorage from "../../../helpers/useStateWithLocalStorage";
// import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

export default function Header(props) {
  const { t } = useTranslation();
  const [token, setToken] = useStateWithLocalStorage("token");
  // const user = useSelector((state) => state.user.user);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="header">
        {/* <div className="container"> */}
        <Navbar collapseOnSelect expand="md" variant="dark" className="py-2 px-2">
          <Container>
            <Navbar.Brand>
              <Link to="/" className="text-decoration-none link-info">
                <img
                  className="_logo"
                  src="/assets/images/head_logo.png"
                  alt="header-logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav.Link
                  key="1"
                  onClick={
                    !token
                      ? () => navigate("/login")
                      : () => {
                          setToken("");
                        }
                  }
                >
                  <FontAwesomeIcon icon={faUser} />{" "}
                  {!token ? t("signin_title") : t("nav_signout")}
                </Nav.Link>
                {token
                  ? [
                      <Nav.Link key="2" onClick={() => navigate("/account")}>
                        <FontAwesomeIcon icon={faUser} /> {t("account")}
                      </Nav.Link>,
                    ]
                  : ""}
                <Language key="3" />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* </div> */}
      </div>
    </>
  );
}
