import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Form, Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import useInput from '../../../../hooks/useInput';
// import { storeUserPhone } from '../../../../features/userSlice';


function SignInModal({ onHide, parentCallback }) {
  const { t } = useTranslation();
  const [, setDidSubmit] = useState(false);
  // const dispatch = useDispatch();

  const {
    // value,
    // setValue,
    phone,
    setPhone,
    mail,
    setMail,
    hasError,
    isValid,
    inputBlurHandler,
    inputTouchedOnConfirm,
  } = useInput();

  const sendSms = async () => {
    await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/auth/message/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mail: mail,
      }),
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    inputTouchedOnConfirm();
    if (hasError) return;
    if (isValid) {
      setDidSubmit(true);
      if (parentCallback) {
        parentCallback(true);
      }
      sendSms();
      // setPhone(phone);
      setMail(mail)
      // console.log(e.value);
      // dispatch(storeUserPhone(+value));
    }
  };

  useEffect(() => {
    document.title = t("signin_title")
  }, []);

  return (
    <Form onSubmit={submitHandler}>
      {onHide ?
        <div className='d-flex v-20 justify-content-end'>
          <Icon icon='ep:close' width='40' height='40' onClick={onHide} />
        </div>
        :
        <span/>
      }
      <div className='d-flex flex-column align-items-center justify-content-center vh-50 px-5'>
        <Icon icon='ic:outline-email' width='200' height='200' />
        <h1 className='mt-5'>{t('signin_title')}</h1>

        <div dir='ltr' className='d-flex flex-column align-items-center'>
          <div className='mb-5 mt-4'>
            {/* <PhoneInput
              inputStyle={{
                fontSize: '25px',
                paddingTop: '25px',
                paddingBottom: '25px',
              }}
              value={phone}
              onChange={setPhone}
              onBlur={inputBlurHandler}
              minLength={9}
              maxLength={10}
              placeholder={t('phone_placeholder')}
              country="il"
              inputProps={{
                autoFocus: true,
                autoformat: "true"
              }}
              // enableAreaCodes={true}
              // disableDropdown={true}
              countryCodeEditable={false}
              // masks={{il: '(..) ....-...'}}
            /> */}

            <input
              type='email'
              value={mail}
              onChange={e => setMail(e.target.value)}
              onBlur={inputBlurHandler}
              minLength={5}
              maxLength={40}
              placeholder={t('mail_placeholder')}
            />

            {hasError && (
              <p className='h6 text-danger mt-3'>{t('field_required')}</p>
            )}
          </div>

          <Button variant='info' type='submit' className='btn-white' size='lg'>
            {t('sendcode_button')}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default SignInModal;
