import { Card } from 'react-bootstrap';
import { Icon } from '@iconify/react';

import './style.css';

export default function LicenseCard({ text, icon, image, widget }) {
  return (
    <Card
      className='scan-container border-0'
      style={{ width: '800px', height: '480px' }}
    >
      <div className='text-center'>
        {icon ? <Icon icon={icon} width='270' height='270' color='#b3cde0' /> : <></>}
        {image ? <img src={image} alt='license' /> : <></>}
      </div>
      <div className='text-center mt-2 mb-2'>
        <Icon
          icon='bi:plus-circle'
          color='#6497b1'
          width='70'
          height='70'
          className='icon-hover'
          onClick={() => widget.open()}
        />
      </div>
      <Card.Body>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}
