import Language from "../../Language";

import "./style.css";

export default function Footer({ showLegal }) {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="footer_block">
            <div className="row">
              <div className="col-lg-2 _col">
                <a href="/" className="d-inline-block">
                  <img
                    className="_logo"
                    src="/assets/images/head_logo.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="col-lg-8 _col">
                <div className="menu_list d-inline-block">
                  <div className="footer_links">
                    <div className="fo_link">
                      <a href="assets/documents/GDPR Cookie Policy.pdf" className={!showLegal ? "d-none" : ""}>
                        GDPR Cookie Policy
                      </a>
                      <a href="assets/documents/GDPR Privacy Policy.pdf" className={!showLegal ? "d-none" : ""}>
                        GDPR Privacy Policy
                      </a>
                      <a href="assets/documents/Guarantees.pdf" className={!showLegal ? "d-none" : ""}>
                        Guarantees
                      </a>
                      <a href="assets/documents/Legal Disclaimer.pdf" className={!showLegal ? "d-none" : ""}>
                        Legal Disclaimer
                      </a>
                      <a href="assets/documents/Privacy and Security.pdf" className={!showLegal ? "d-none" : ""}>
                        Privacy and Security
                      </a>
                      <a href="assets/documents/Shipping Policy.pdf" className={!showLegal ? "d-none" : ""}>
                        Shipping Policy
                      </a>
                      <a href="assets/documents/Terms and Conditions.pdf" className={!showLegal ? "d-none" : ""}>
                        Terms and Conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 text-start _col">
                <div className="d-inline-block">
                  <span className="lang_txt"><img src="/assets/images/flag.png" alt="" /> Language</span>
                  <Language></Language>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg_full"></div>
      </div>
    </>
  );
}
