import { Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LicenseCard from './LicenseCard';
// import UserHeader from '../UserAccount/UserHeader';
import Header from '../../components/marginals/Header';
import Footer from '../../components/marginals/Footer';
import useWidget from '../../hooks/useWidget';
import React, { useEffect } from 'react';

export default function ScanLicense() {
  const {
    isUploadFront,
    setIsUploadFront,
    isUploadBack,
    setIsUploadBack,
    widget1,
    widget2,
  } = useWidget();

  React.useEffect(() => {
    return function cleanupListener() {
      widget1.destroy();
      widget2.destroy();
    }
  })

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("license_title")
  }, []);

  return (
    <div>
      <Header />
      <h1 className='mt-4 mb-3'>{t('license_title')}</h1>
      <div className='d-flex flex-column align-items-center column-gap'>
        <div className='mb-4 mt-3 border-dashed'>
          {isUploadFront ? (
            <>
              <Image id='imagefront' src='' className='thumbnail' />
              <div className='d-flex justify-content-center mt-2'>
                <p className='mx-5'>FRONT PHOTO</p>
                <OverlayTrigger
                  delay={{ hide: 300, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Try another photo</Tooltip>
                  )}
                  placement='right'
                >
                  <Icon
                    icon='akar-icons:trash-can'
                    width='35'
                    height='35'
                    color='#39c0ed'
                    onClick={() => setIsUploadFront(false)}
                  />
                </OverlayTrigger>
              </div>
            </>
          ) : (
            <LicenseCard
              text={
                <>
                  {t('license_text')} <br />({t('license_front')})
                </>
              }
              // icon={'fa:drivers-license-o'}
              image={'/assets/images/front.jpg'}
              widget={widget1}
            />
          )}
        </div>
        <div className='mb-5 mt-5 border-dashed'>
          {isUploadBack ? (
            <>
              <Image id='imageback' src='' className='thumbnail' />
              <div className='d-flex justify-content-center mt-2'>
                <p className='mx-5'>BACK PHOTO</p>
                <OverlayTrigger
                  delay={{ hide: 300, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>Try another photo</Tooltip>
                  )}
                  placement='right'
                >
                  <Icon
                    icon='akar-icons:trash-can'
                    width='35'
                    height='35'
                    color='#39c0ed'
                    onClick={() => setIsUploadBack(false)}
                  />
                </OverlayTrigger>
              </div>
            </>
          ) : (
            <LicenseCard
              text={
                <>
                  {t('license_text')} <br />({t('license_back')})
                </>
              }
              // icon={'bi:card-list'}
              image={'/assets/images/back.jpg'}
              widget={widget2}
            />
          )}
        </div>
      </div>
      <div className='d-flex flex-column align-items-center px-5 my-4'>
        <p className='h3'>{t('license_confirm')}</p>
        <Button
          variant='info'
          className='btn-white mt-3 mb-5'
          size='lg'
          onClick={() => navigate('/checkout')}
        >
          {t('license_button')}
        </Button>
      </div>
      <Footer />
    </div>
  );
}
