import { useState, useEffect} from 'react';

const loadLogRocketAsync = (creds, cb) => {
  const existingScript = document.getElementById('logrocket');

  if (!existingScript) {
    const script = document.createElement('script');

    //<script src="https://cdn.lr-ingest.io/LogRocket.min.js" crossorigin="anonymous"></script>

    script.src = 'https://cdn.lr-ingest.io/LogRocket.min.js'; // URL for the third-party library being loaded.
    script.id = 'logrocket'; // e.g., googleMaps or stripe
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    script.onload = () => {
      if (cb) cb();
    };
  }

  // if (existingScript && cb) cb();
};

const LogRocket = ({ creds }) => {

  const [credentials, setCredentials] = useState(creds);
  // const [ready, setReady] = useState(false);

  useEffect(() => {
    loadLogRocketAsync(credentials, init);
  }, []);

  const init = () => {
    // console.log('cb called');
    if (window.location.hostname !== "localhost" &&
      window.location.search.indexOf("ignore=true") === -1) {
      window.LogRocket && window.LogRocket.init(credentials);
    }
  }

  return null;
};

export default LogRocket;
