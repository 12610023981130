import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Homepage from './pages/Homepage'
// import Main from './pages/Main';
import Login from './pages/Login';
import ProductsPage from './pages/ProductsPage';
import LicenseScan from './pages/LicenseScan';
import UserAccount from './pages/UserAccount';
import Message from './pages/PayPal/Message';
import Checkout from './pages/PayPal/Checkout';
import ProtectedRoutes from './helpers/ProtectedRoutes';
import ScrollToTop from './components/scrolls/ScrollToTop';
import TechFooter from './components/TechFooter';
import Document from './pages/Document';
// import HomepageTester from './pages/HomepageTester';

export default function App() {
  const { t } = useTranslation();

  return (
    <div className='App'>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Homepage />} />
            {/* <Route path='/old' element={<Main />} />
            <Route path='/new' element={<Homepage />} /> */}
            <Route path='login' element={ <Login />} />
            <Route path='products' element={<ProductsPage />} />
            <Route element={<ProtectedRoutes />}>
              <Route path='postPayment' element={<Message />} />
              <Route path='checkout' element={<Checkout />} />
              <Route path='license' element={<LicenseScan />} />
              <Route path='account' element={<UserAccount />} />
              <Route path='document/:docId' element={ <Document />} />
            </Route>
            <Route
              path='*'
              element={
                <main style={{ padding: '1rem' }}>
                  <p>{t('nothing_page')}</p>
                </main>
              }
            />
          </Routes>
        </ScrollToTop>
        <TechFooter enableLogRocket={false} />
      </Router>
    </div>
  );
}
