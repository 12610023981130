import Header from "../../components/marginals/Header";
import SignInModal from "../../components/forms/Modals/SignInModal";
import Footer from "../../components/marginals/Footer";
import { useCallback, useEffect, useState } from "react";
import MainVerifyModal from "../../components/forms/Modals/MainVerifyModal";
import useStateWithLocalStorage from "../../helpers/useStateWithLocalStorage";

export default function Login() {
  const [didSubmit, setDidSubmit] = useState(false);
  const [token, setToken] = useStateWithLocalStorage("token");

  const callback = useCallback((didSubmit) => {
    setDidSubmit(didSubmit);
  }, []);

  useEffect(() => {
    // cleanup token if we got to the login page
    setToken("");
  }, []);

  return (
    <>
      <Header />
      {!didSubmit && <SignInModal parentCallback={callback} />}
      {didSubmit && <MainVerifyModal />}
      <Footer />
    </>
  );
}
