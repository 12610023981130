import { createSlice } from '@reduxjs/toolkit';

export const documentSlice = createSlice({
  name: 'document',
  initialState: {
    product: {},
  },
  reducers: {
    detectProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});

export const { detectProduct } = documentSlice.actions;

export default documentSlice.reducer;
