export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

// return true only if the token is valid and expires in later then 1 minute from now
export function isValid(token) {
  console.log('checking token validity...')
  if (token && token !== 'undefined') {
    const parsedToken = parseJwt(token);
    if (parsedToken && parsedToken.exp) {
      if (new Date().getTime() / 1000 < parsedToken.exp - 60) {
        return true
      }
    }
  }
  return false;
}