import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';

import Language from "../../Language";
// import { userIsUnauthenticated } from '../../../features/userSlice';
import useStateWithLocalStorage from "../../../helpers/useStateWithLocalStorage";
// import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const { t } = useTranslation();
  const [token, setToken] = useStateWithLocalStorage("token");
  // const user = useSelector((state) => state.user.user);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Navbar
      collapseOnSelect
      expand="none"
      className="py-2 px-2"
      bg="light"
      variant="light"
    >
      <Container>
        <Navbar.Brand>
          <Link to="/" className="text-decoration-none link-info">
            {t("logo")}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link
              key="1"
              style={{ color: "#39c0ed" }}
              onClick={
                !token
                  ? () => navigate("/login")
                  : () => {
                      setToken("");
                    }
              }
            >
              {!token ? t("signin_title") : t("nav_signout")}
            </Nav.Link>
            {token
              ? [
                  <Nav.Link
                    key="2"
                    style={{ color: "#39c0ed" }}
                    onClick={() => navigate("/account")}
                  >
                    {t("account")}
                  </Nav.Link>,
                ]
              : ""}
            <Language key="3" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
