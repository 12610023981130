import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

// import { userIsAuthenticated } from '../../../features/userSlice';

export default function Message() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setTitle(t('success_title'));
      setMessage(t('success_text'));
      document.title = t("success_title")
    }

    if (query.get('canceled')) {
      setTitle(t('cancel_title'));
      setMessage(t('cancel_text'));
      document.title = t("cancel_title")
    }
  }, [t]);

  const clickMessageHandler = () => {
    // dispatch(userIsAuthenticated());
    navigate('/account');
  };

  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={clickMessageHandler}>
          {t('button')}
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}
