import { Card, Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import useStateWithLocalStorage from '../../../helpers/useStateWithLocalStorage';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useNavigate } from 'react-router-dom';

const amount = '1';
const style = { layout: 'vertical' };

export default function ButtonWrapper({ currency, showSpinner }) {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  const [documentId, setDocumentId] = useStateWithLocalStorage('documentId');
  const [product, setProduct] = useStateWithLocalStorage('product');
  const parsedProduct = product ? JSON.parse(product) : {};

  return (
    <Container style={{ maxWidth: "800px" }}>
      <Card>
        {showSpinner && isPending && <div className='spinner' />}
        <Card.Header>PayPal Checkout</Card.Header>
        <Card.Body>
          <Card.Title>{t(parsedProduct.name)}</Card.Title>
          <Card.Text>{parsedProduct.price}</Card.Text>
          <PayPalButtons
            style={style}
            disabled={false}
            forceReRender={[amount, currency, style]}
            fundingSource={undefined}
            createOrder={async () => {
              try {
                const response = await fetch(
                  `${process.env.REACT_APP_API_SERVER_URL}/api/order`,
                  {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      productId: parsedProduct._id,
                      documentId: documentId,
                      quantity: 1,
                    }),
                  }
                );
                const data = await response.json();
                if (response.ok) {
                  return data.id;
                }
                return await Promise.reject(data);
              } catch (error) {
                console.error(error);
              }
            }}
            onApprove={function (data, actions) {
              return actions.order.capture().then( async function (details) {
                console.log(
                  `Transaction by ${details.payer.name.given_name}`
                );
                console.debug(details);
                navigate('/postPayment?success=true');
              });
            }}
          />
        </Card.Body>
        <Card.Footer className='text-muted'>Money back guarantee!</Card.Footer>
      </Card>
    </Container>
  );
}
