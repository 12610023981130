import Header from '../../components/marginals/Header';
import Products from '../../components/main/Products';
import Footer from '../../components/marginals/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function ProductsPage() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("product")
  }, []);

  return (
    <>
      <Header />

      <Products />
      <Footer />
    </>
  );
}
