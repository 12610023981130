import { useState } from 'react';
import useStateWithLocalStorage from '../helpers/useStateWithLocalStorage';

export default function useInput() {
  // const [phone, setPhone] = useStateWithLocalStorage('phone');
  const [mail, setMail] = useStateWithLocalStorage('mail');
  // const [value, setValue] = useState();
  const [isTouched, setIsTouched] = useState(false);
  let isValid = mail && mail.match(/^\S+@\S+\.\S+$/) !== null
  // let isValid = phone.length === 12 || phone.length === 13;
  let hasError = !isValid && isTouched;

  const inputBlurHandler = () => {
    setIsTouched(true);
  };

  const inputTouchedOnConfirm = () => {
    setIsTouched(true);
  };

  return {
    // value,
    // setValue,
    // phone,
    // setPhone,
    mail,
    setMail,
    hasError,
    isValid,
    inputBlurHandler,
    inputTouchedOnConfirm,
  };
}
