import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DropdownButton, Dropdown, NavDropdown } from "react-bootstrap";
import i18next from "i18next";
import cookies from "js-cookie";

import "./style.css";

const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "he",
    name: "עברית",
    dir: "rtl",
  },
  {
    code: "ar",
    name: "عربى",
    dir: "rtl",
  },
];

export default function Language() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <NavDropdown title={currentLanguage.name} id="dropdown-basic-button">
      {languages.map(({ code, name }) => (
        <NavDropdown.Item
          eventKey={code}
          onClick={() => i18next.changeLanguage(code)}
        >
          {name}
        </NavDropdown.Item>
      ))}
  </NavDropdown>

      // <img className="lang_flag" src="/assets/images/flag.png" alt="" />
      // <DropdownButton
      //   variant='outline-info'
      //   id="dropdown-basic-button"
      //   size="md"
      //   title={currentLanguage.name}
      // >
      //   {languages.map(({ code, name }) => (
      //     <Dropdown.Item
      //       key={code}
      //       onClick={() => i18next.changeLanguage(code)}
      //     >
      //       {name}
      //     </Dropdown.Item>
      //   ))}
      // </DropdownButton>
  );
}
