import { Form, Button } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useStateWithLocalStorage from '../../../../helpers/useStateWithLocalStorage';
import {parseJwt} from '../../../../helpers/auth'
// import { userIsAuthenticated } from '../../../../features/userSlice';

function MainVerifyModal(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [verifyValue, setVerifyValue] = useState('');
  const dispatch = useDispatch();
  // const phone = useSelector((state) => state.user.phone);
  // const [phone, setPhone] = useStateWithLocalStorage('phone');
  const [mail, setMail] = useStateWithLocalStorage('mail');
  const [token, setToken] = useStateWithLocalStorage('token');
  const [product, setProduct] = useStateWithLocalStorage('product');

  const changeHandlerVerify = (e) => {
    setVerifyValue(e.target.value);
  };

  async function login(mail, code) {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/auth/login/`,
      // `${process.env.REACT_APP_API_SERVER_URL}/api/auth/login/?phone=${phone}&code=${code}`,
      {
        credentials: 'include',
        // credentials: 'same-origin',
        // method: 'GET',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({
          mail,
          code,
          clientId: window.cid ? window.cid : "",
        }),
      }
    );

    const result = await response.json();
    let token = result.token;
    console.log(token);
    setToken(token);
    return token;
  }

  const submitHandlerVerify = async (e) => {
    e.preventDefault();

    const token = await login(mail, verifyValue);
    if (token) {
      // dispatch(userIsAuthenticated(token));

      if (props.onHide) {
        props.onHide();
      }

      // if the client paid in the past send him to his account dashboard
      if (parseJwt(token).phone) {
        navigate('/account');
      } else {
        if (product) {
          navigate('/license');
        } else {
          navigate('/products');
        }
      }
    }
    return;
  };

  useEffect(() => {
    document.title = t("verify_title")
  }, []);

  return (
    <Form onSubmit={submitHandlerVerify}>
      {props.onHide ?
        <div className='d-flex v-20 justify-content-end'>
          <Icon icon='ep:close' width='40' height='40' onClick={props.onHide} />
        </div>
        :
        <span />
      }
      <div className='d-flex flex-column align-items-center justify-content-center vh-50 px-5'>
        <Icon icon='carbon:code-signing-service' width='200' height='200' />
        <h1 className='mt-4 mb-3'>{t('verify_title')}</h1>
        <div className='d-flex flex-column align-items-center'>
          <div style={{ width: '210px' }}>
            <p className='mt-3'>{t('verify_enter')} {mail}</p>
          </div>
          <div className='mb-3 mt-3'>
            <input
              type='tel'
              maxLength={4}
              placeholder={t('sms_placeholder')}
              value={verifyValue}
              onChange={changeHandlerVerify}
            />
          </div>

          <Button
            variant='info'
            type='submit'
            className='btn-white mt-4'
            size='lg'
          >
            {t('validate_button')}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default MainVerifyModal;
