import { Card, ListGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { useSelector } from 'react-redux';
// import useStateWithLocalStorage from "../../../helpers/useStateWithLocalStorage";

import "./style.css";

export default function ProductCard(props) {
  const { enabled, name, price, delivery, time, features, icon, onClick } =
    props;
  const { t } = useTranslation();
  // const user = useSelector((state) => state.user.user);
  // const [token, setToken] = useStateWithLocalStorage('token');

  return (
    <>
      <Card
        className="products-container"
        style={{ width: "50rem", height: "37rem" }}
      >
        <Card.Header className="card-header h2 py-4">{t("digital_license")} {name}</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item
              variant="secondary"
              style={{ fontSize: 50, color: "orange" }}
            >
              {price}$
            </ListGroup.Item>
            <ListGroup.Item>{icon}</ListGroup.Item>
            <ListGroup.Item>{t("readyIn")} {time}</ListGroup.Item>
            <ListGroup.Item style={{ fontSize: 22, color: "orange" }}>
              {features}
            </ListGroup.Item>
            <ListGroup.Item className="mb-5">
              {t("validOneYear")}
            </ListGroup.Item>
            <ListGroup.Item variant="secondary">
              <Button
                variant="outline-info"
                disabled={!enabled}
                className="px-5 border-2 btn-blue"
                size="lg"
                onClick={onClick}
              >
                <strong>
                  {enabled ? t("buy_button") : t("not_available")}
                </strong>
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
}
