import { configureStore } from '@reduxjs/toolkit';
import documentReducer from '../features/documentSlice';
import userReducer from '../features/userSlice';

export default configureStore({
  reducer: {
    document: documentReducer,
    user: userReducer,
  },
});
