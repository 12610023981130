import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: false,
    mail: null,
    token: null,
  },
  reducers: {
    storeUserMail: (state, action) => {
      state.mail = action.payload;
    },
    userIsAuthenticated: (state, action) => {
      state.user = true;
      state.token = action.payload;
    },
    userIsUnauthenticated: (state) => {
      state.user = false;
    },
  },
});

export const { storeUserMail, userIsAuthenticated, userIsUnauthenticated } =
  userSlice.actions;

export default userSlice.reducer;
